<template>
    <div class="my-page">
        <TitlePage></TitlePage>
        <CommonTitle :text="text" :cont="cont"></CommonTitle>
        <div class="cont-container">
            <div class="cont-box">
                <div class="contL">
                    <router-view />
                </div>
                <div v-if="fullWidth>900">
                    <User></User>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitlePage from '../../components/TitlePage'
import CommonTitle from '../../components/CommonTitle'
import User from '../../components/User'
export default {
    name: "MyPage",
    components: {
        TitlePage,
        CommonTitle,
        User,
    },
    data(){
        return{
            fullWidth: 0,
            text:this.$t('我的主页'),
            cont:this.$t('您可以编辑您的个人信息并管理服务应用程序。我们将尽力保护您的个人信息。'),
        }
    },
    mounted(){
        this.handleResize()
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
    watch:{
        '$route.path':function(newVal){
            if(newVal === '/mypage/coupon'){
                this.text = this.$t('我的优惠券'),
                this.cont = this.$t('您可以编辑您的个人信息并管理服务应用程序。我们将尽力保护您的个人信息。')
            }
            if(newVal === '/mypage/center'){
                this.text = this.$t('我的主页'),
                this.cont = this.$t('您可以编辑您的个人信息并管理服务应用程序。我们将尽力保护您的个人信息。')
            }
        }
    }
}
</script>

<style lang="scss">
.my-page .cont-container{
    max-width: 1220px;
    margin: 0 auto;
    min-height: 1000px;
    @media only screen and (max-width: 900px){
        margin: 20px;
    }
    .cont-box{
        display: flex;
        .contL{
            width: 70%;
            margin-top: 80px;
            @media only screen and (max-width: 900px){
                width: 100%;
                margin-top: 50px;
            }
        }
    }
}
</style>